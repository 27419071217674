import request from '@/utils/request'


// 查询入驻时长（店铺）列表
export function listShopEntry(query) {
  return request({
    url: '/shop/shop/entry/list',
    method: 'get',
    params: query
  })
}

// 查询入驻时长（店铺）分页
export function pageShopEntry(query) {
  return request({
    url: '/shop/shop/entry/page',
    method: 'get',
    params: query
  })
}

// 查询入驻时长（店铺）详细
export function getShopEntry(data) {
  return request({
    url: '/shop/shop/entry/detail',
    method: 'get',
    params: data
  })
}

// 新增入驻时长（店铺）
export function addShopEntry(data) {
  return request({
    url: '/shop/shop/entry/add',
    method: 'post',
    data: data
  })
}

// 修改入驻时长（店铺）
export function updateShopEntry(data) {
  return request({
    url: '/shop/shop/entry/edit',
    method: 'post',
    data: data
  })
}

// 删除入驻时长（店铺）
export function delShopEntry(data) {
  return request({
    url: '/shop/shop/entry/delete',
    method: 'post',
    data: data
  })
}
